import { useEffect, useState } from "react";
import "./App.css";
import {
  Container,
  Layout,
  DataTable,
  Row,
  Column
} from "topsoe-ui-react";
import "topsoe-ui-react/dist/style.css";
import axios from "axios";
import Modal from "./components/Modal";

const stubbedData = {
  headers: [
    { label: "JobId", value: "jobId" },
    { label: "Start Time", value: "startTime" },
    { label: "Status", value: "status" },
    { label: "End Time", value: "endTime" },
  ],
  rows: [],
};

function App() {
  const [data, setdata] = useState(stubbedData);
  const [showModal, setShowModal] = useState(false);
  const [jobIdList, setJobIdList] = useState(null);
  const [xmlArray, setXmlArray] = useState(null);


  const handleClick = async (data) => {
    try {
      var currentURL = window.location.href;
      const dataResponse = await axios.get(`${currentURL}${data}`);
      const sortedList = dataResponse.data.sort((a, b) => {
        const startTimeA = a.startTime;
        const startTimeB = b.startTime;

        if (startTimeA > startTimeB) {
          return -1;
        }
        if (startTimeA < startTimeB) {
          return 1;
        }
        return 0;
      });

      const stbdata = {
        headers: [
          { label: "Type", value: "type" },
          { label: "JobId/ProductId", value: "jobId" },
          { label: "Start Time", value: "startTime" },
          { label: "Status", value: "status" },
          { label: "End Time", value: "endTime" },
        ],
        rows: sortedList,
      };

      setdata(stbdata);
      const extractedProperties = {};
      dataResponse.data.forEach((element) => {
        if (!extractedProperties[element.jobId]) {
          extractedProperties[element.jobId] = [];
        }
        extractedProperties[element.jobId].push(element.inputTemplate);
        extractedProperties[element.jobId].push(element.output);
      });

      setJobIdList(extractedProperties);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const showXml = (rowData) => {
    setXmlArray(jobIdList[rowData.data.jobId]);
    setShowModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleClick("getlatestdata");
    };
    fetchData();
  }, []);

  return (
    <Layout>
      <Container>
        <Row rows="twelve">
          <Column columns="twelve" className="bg-primary-dark-5 text-secondary">
            <h3 className="text-secondary">CPQ Gips Job Dashboard </h3>
          </Column>
        </Row>
        <Row className="flex-1">
          <Column columns="twelve" style={{ margin: "5px" }}>
            <div style={{ display: "flex" }}>
              <button
                className="hover-button"
                onClick={() => {
                  handleClick("getlatestdata");
                }}
              >
                Refresh
              </button>
              <button
                onClick={() => {
                  handleClick("getdata");
                }}
              >
                All JobID
              </button>
            </div>
            <DataTable
              data={data}
              callback={showXml}
              style={{ cursor: "pointer" }}
            ></DataTable>
          </Column>
        </Row>
        {showModal && (
          <Modal onSeen={() => setShowModal(false)} xmlString={xmlArray} />
        )}
      </Container>
    </Layout>
  );
}

export default App;
