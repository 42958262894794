import React, { useEffect, useState } from "react";
import "./Modal.css";
import {
  Container,
  Layout,
  DataTable,
  Row,
  Column,
  Dialog,
  Header,
} from "topsoe-ui-react";
function Modal({ onSeen, xmlString }) {
  const [inputTemplate, setInputTemplate] = useState(null);
  const [outputTemplate, setoutputTemplate] = useState(null);
  useEffect(() => {
    // Parse the XML string into an XML document object
    const parser = new DOMParser();
    const xmlDoc1 = parser.parseFromString(xmlString[0], "application/xml");
    const xmlDoc2 = parser.parseFromString(xmlString[1], "application/xml");
   
    
    setInputTemplate(xmlDoc1);
    setoutputTemplate(xmlDoc2);
  }, [xmlString]);

  return (
    <div className="md">
      <div className="overlay">
        <div className={`centered-dialog fullscreen`}>
          <div className="dialog-content">
            <Row style={{Display:'flex'}}>
              <Column
                columns="six"
                id="console"
                className="bg-primary-dark-5 text-accent"
              >
                <Row>Input</Row>
                {inputTemplate ? (
                  <pre>
                    {new XMLSerializer().serializeToString(inputTemplate)}
                  </pre>
                ) : (
                  <p>Loading...</p>
                )}
              </Column>
              <Column
                columns="six"
                id="console"
                className="bg-primary-dark-5 text-accent"
              >
                <Row className="seen" style={{ display:""}}>
                  <span>Output</span>
                </Row>
                {outputTemplate ? (
                  <pre>
                    {new XMLSerializer().serializeToString(outputTemplate)}
                  </pre>
                ) : (
                  <p>Loading...</p>
                )}
              <Row><button onClick={onSeen}>
                  Close
              </button></Row>
              </Column>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
